import { DashboardWrapper, MobileWrapper, DesktopWrapper } from './styled'

import Nav from './dashboard-components/navigation'
import Goals from './dashboard-components/goals'

import { useState, useEffect } from 'react'

// import { useOutsideAlerter } from 'hooks/outsideAlerter'

const Dashboard = () => {
  const [windowDimension, setWindowDimension] = useState(null)
  const [mobileSection, setMobileSection] = useState(null)

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const renderMobileSection = (section) => {
    switch (mobileSection) {
      case 'goals':
        return <Goals />
      case 'overview':
        return <div>Overview</div>
      case 'metrics':
        return <div>Metrics</div>
      default:
        return <div>Mobile</div>
    }
  }

  const isMobile = windowDimension <= 640
  return (
    <DashboardWrapper id="container">
      <Nav onSelect={setMobileSection} />
      {isMobile && renderMobileSection(mobileSection)}
      {!isMobile && (
        <DesktopWrapper>
          <Goals />
          <div>Overview</div>
          <div>Metrics</div>
        </DesktopWrapper>
      )}
    </DashboardWrapper>
  )
}

export default Dashboard
