import Home from './pages/Home'
import { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import { VerifyPage } from './pages/Auth'
import { FourOFour } from './pages/404'

function App() {
  const [session, setSession] = useState(null)

  let createSession = async () => {
    try {
      const response = await fetch(
        'https://us-central1-tbdhow.cloudfunctions.net/createSession',
        {
          method: 'GET',
        },
      )
      const data = await response.json()
      if (data.sessionId) setSession(data.sessionId)
      console.log('session created successfully', data.sessionId)
    } catch {
      console.log('error creating session')
    }
  }

  // write a useEffect hook that calls createSession once and only once
  // when the component mounts
  // useEffect(() => {
  //   // ensure that createSession is only called once
  //   if (!session) {
  //     createSession()
  //   }
  // }, [])

  useEffect(() => {
    if (!session) {
      createSession()
    }
  }, [session])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home sessionId={session} />} />
        <Route path="/verify/*" element={<VerifyPage />} />
        <Route path="*" element={<FourOFour />} />
      </Routes>
    </BrowserRouter>
  )
}

// function VerifyPage() {
//   return (
//     <div>
//       <nav>
//         <Link to="/">Home</Link>
//       </nav>

//       {/* <Routes>
//         <Route path=":id" element={<UserProfile />} />
//         <Route path="me" element={<OwnUserProfile />} />
//       </Routes> */}
//     </div>
//   )
// }

export default App
