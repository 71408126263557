import {
  Wrapper,
  OptionsWrapper,
  Option,
  LogoOption,
  GoalOption,
  ActionOption,
  UpgradeOption,
  ActionsWrapper,
  UpgradeWrapper,
  HelpWrapper,
  StyledPopup,
  PopUpMessage,
  InputForm,
} from './styled'

import Error from './Error'

import { useState, useEffect } from 'react'
import { HelpCircle } from 'react-feather'

import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

import { sendLeadGeneratorLink } from '../../helpers'

import { DayContent, DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { roundToNearestMinutes, set } from 'date-fns'

import { useSize } from '../../contexts/sizeContext'
import { useDeferredValue } from 'react'

const Quiz = (props) => {
  const SEGMENTS = [
    {
      title: 'Building a Business',
      buttonName: 'segment-founderish',
      description: "You're looking to launch or build a business",
      milestones: [
        {
          title: 'Determined your target customers',
          buttonName: 'startup-target-customers',
          description:
            "You've identified your target customers and their needs",
        },
        {
          title: 'Identified the problem you are solving',
          buttonName: 'startup-problem',
          description:
            "You've identified the problem you are solving for your customers",
        },
        {
          title: 'Developed a product or service',
          buttonName: 'startup-product',
          description:
            "You've developed a product or service to solve your customers' problem",
        },
        {
          title: 'Conducted market research',
          buttonName: 'startup-market-testing',
          description:
            "You've done research to validate your product or service",
        },
        {
          title: 'Made sales',
          buttonName: 'startup-sales',
          description: "You've made your first sales",
        },
        {
          title: 'Built a data-driven workflow',
          buttonName: 'startup-workflow',
          description:
            "You've built a process to track your progress and make data-driven decisions",
        },
      ],
    },
    {
      title: 'Innovating an Org',
      buttonName: 'segment-corporatepreneur',
      description: "You're working internal to an existing org to innovate",
      milestones: [
        {
          title: 'Analyzed current org needs',
          buttonName: 'corporate-needs',
          description: "You've analyzed the needs of your organization",
        },
        {
          title: 'Defined SMART goals or KPIs',
          buttonName: 'corporate-goals',
          description:
            "You've defined metric based goals or performance indicators to measure your progress",
        },
        {
          title: 'Developed a strategic plan',
          buttonName: 'corporate-strategic-plan',
          description: "You've got a clear plan for how to achieve your goals",
        },
        {
          title: 'Mobilized key stakeholders',
          buttonName: 'corporate-stakeholders',
          description:
            "You've got the right people on board to help you achieve your goals",
        },
        {
          title: 'Built a data-driven workflow',
          buttonName: 'corporate-workflow',
          description:
            "You've built a process to track your progress and make data-driven decisions",
        },
        {
          title: 'Created a culture of innovation',
          buttonName: 'corporate-culture',
          description:
            "You've established a culture that supports and sustains innovation and change within your organization",
        },
      ],
    },
    {
      title: 'Solving a Problem',
      buttonName: 'segment-solutioneer',
      description:
        "You're solving a problem for your self, your community or the world",
      milestones: [
        {
          title: 'Identified a clear problem or goal',
          buttonName: 'solutioneer-goal',
          description:
            'You have a well defined understanding of the problem you are solving',
        },
        {
          title: 'Determined key stakeholders involved',
          buttonName: 'solutioneer-stakeholders',
          description:
            "You've identified the people who are impacted by the problem you are solving",
        },
        {
          title: 'Developed a solution',
          buttonName: 'solutioneer-solution',
          description: "You've built something to solve the problem",
        },
        {
          title: 'Created a strategic plan',
          buttonName: 'solutioneer-strategic-plan',
          description: "You've got a clear plan for how to achieve your goals",
        },
        {
          title: 'Identified metrics to test progress',
          buttonName: 'solutioneer-metrics',
          description: "You've identified metrics to measure your progress",
        },
        {
          title: 'Set up a data-driven workflow',
          buttonName: 'solutioneer-workflow',
          description:
            "You've built a process to track your progress and make data-driven decisions",
        },
      ],
    },
  ]

  const STARTUP_GOALS = [
    {
      title: 'Generate Revenue',
      buttonName: 'goal-generate-revenue',
      description: "You're looking to increase cash flow",
    },
    {
      title: 'Grow Audience',
      buttonName: 'goal-grow-audience',
      description:
        "You're looking to increase your customer base, leads, subscribers or users",
    },
    {
      title: 'Streamline Operations',
      buttonName: 'goal-streamline-operations',
      description:
        "You're looking to improve your internal processes like hiring, onboarding, or customer support",
    },
  ]

  const MILESTONES = {
    Revenue: [
      {
        title: 'Identified Customers',
        buttonName: 'milestone-identified-customers',
        description: 'You know your target customers and their needs',
      },
      {
        title: 'Developed a Business Model',
        buttonName: 'milestone-business-model',
        description:
          'You understand how your business will make money and the costs involved',
      },
      {
        title: 'Built a Sales Funnel',
        buttonName: 'milestone-sales-funnel',
        description:
          'You have a clear process for converting leads into customers',
      },
      {
        title: 'Made sales',
        buttonName: 'milestone-made-sales',
        description: 'You have already made your first sales',
      },
      {
        title: 'Consistent cash flow',
        buttonName: 'milestone-cash-flow',
        description:
          'You have consistent cash flow from your business operations',
      },
      {
        title: 'Diversified revenue streams',
        buttonName: 'milestone-revenue-streams',
        description:
          'You have multiple revenue streams to support your business',
      },
    ],
    Audience: [
      {
        title: 'Identified Customers',
        buttonName: 'milestone-identified-customers',
        description: 'You know your target customers and their needs',
      },
      {
        title: 'Developed a Marketing Plan',
        buttonName: 'milestone-marketing-plan',
        description: 'You have a clear plan for reaching your target customers',
      },
      {
        title: 'Built a Sales Funnel',
        buttonName: 'milestone-sales-funnel',
        description:
          'You have a clear process for converting leads into customers',
      },
      {
        title: 'Established a brand identity',
        buttonName: 'milestone-brand-identity',
        description:
          'You have a clear brand identity and narrative that resonates with your target customers',
      },
      {
        title: 'Designed a website',
        buttonName: 'milestone-website',
        description:
          'You have a website that clearly communicates your value proposition and converts visitors into customers',
      },
      {
        title: 'Built a content strategy',
        buttonName: 'milestone-content-strategy',
        description:
          'You have a content strategy that attracts and engages your target customers',
      },
    ],
    Operations: [
      {
        title: 'Created a Strategic Plan',
        buttonName: 'milestone-strategic-plan',
        description:
          'You have a clear plan for how to measure and achieve your goals',
      },
      {
        title: 'Outlined standard operating procedures',
        buttonName: 'milestone-standardized-ops',
        description:
          'You have documented the processes for your key business operations',
      },
      {
        title: 'Conducted a SWOT Analysis',
        buttonName: 'milestone-swot',
        description:
          'You have identified your business strengths, weaknesses, opportunities, and threats',
      },
      {
        title: 'Built a team',
        buttonName: 'milestone-team',
        description:
          'You have a team of people with the skills and experience to help you achieve your goals',
      },
      {
        title: 'Established a company culture',
        buttonName: 'milestone-culture',
        description:
          'You have a clear company culture that aligns with your values and goals',
      },
      {
        title: 'Automated key processes',
        buttonName: 'milestone-automation',
        description: 'You have automated key processes to save time and money',
      },
    ],
    Team: [
      {
        title: "It's just me",
        buttonName: 'milestone-solo-founder',
        description: "It's just me",
      },
      {
        title: 'Co-founders or partners',
        buttonName: 'milestone-co-founders',
        description: "You've got co-founders or partners",
      },
      {
        title: 'Hired a team',
        buttonName: 'milestone-hired-team',
        description:
          "You've hired a team (part or full time) to help you achieve your goals",
      },
      {
        title: 'Managing a team',
        buttonName: 'milestone-managing-team',
        description: "You're responsible for managing a team",
      },
      {
        title: 'Outsourced to contractors',
        buttonName: 'milestone-contractors',
        description: "You're currently working with contractors or freelancers",
      },
      {
        title: 'Working with advisors',
        buttonName: 'milestone-advisors',
        description: "You're working with advisors or mentors",
      },
    ],
  }

  const MODELS = [
    {
      title: 'Software as a service',
      buttonName: 'bm-saas',
      description: 'You sell software on a subscription basis',
    },
    {
      title: 'Transactional',
      buttonName: 'bm-transactional',
      description: 'You facilitate transactions and take a cut',
    },
    {
      title: 'Marketplace',
      buttonName: 'bm-marketplace',
      description: 'You facilitate transactions between buyers and sellers',
    },
    {
      title: 'Subscription',
      buttonName: 'bm-subscription',
      description:
        'You sell access to content or services on a subscription basis',
    },
    {
      title: 'Enterprise',
      buttonName: 'bm-enterprise',
      description:
        'You sell large fixed-term contracts to big companies 5k+ employees',
    },
    {
      title: 'Usage Based',
      buttonName: 'bm-usage-based',
      description:
        'You charge customers based on how much they use your product',
    },
    {
      title: 'E-commerce',
      buttonName: 'bm-ecommerce',
      description: 'You sell physical or digital goods online',
    },
    {
      title: 'Advertising',
      buttonName: 'bm-advertising',
      description: 'You sell ad space on your platform',
    },
    {
      title: 'Service Based',
      buttonName: 'bm-service-based',
      description: 'You sell services on a project or retainer basis',
    },
  ]

  const AUDIENCE = [
    {
      title: 'General Consumers',
      buttonName: 'aud-general-consumers',
      description: 'You target members of the general public',
    },
    {
      title: 'Online Shoppers',
      buttonName: 'aud-online-shoppers',
      description: 'You target people who shop online',
    },
    {
      title: 'Internet Users',
      buttonName: 'aud-internet-users',
      description: 'You target people who use the internet',
    },
    {
      title: 'Business or Enterprise',
      buttonName: 'aud-business-enterprise',
      description: 'You target businesses or enterprises',
    },
    {
      title: 'Education',
      buttonName: 'aud-education',
      description: 'You target students, teachers, or schools',
    },
    {
      title: 'Technology Developers',
      buttonName: 'aud-technology-developers',
      description: 'You target developers or engineers',
    },
    {
      title: 'Financial Services',
      buttonName: 'aud-financial-services',
      description: 'You target financial services professionals',
    },
    {
      title: 'Health Care',
      buttonName: 'aud-health-care',
      description: 'You target health care professionals',
    },
    {
      title: 'Government',
      buttonName: 'aud-government',
      description: 'You target government employees',
    },
  ]

  const TIMELINE = [
    {
      title: 'End of the month',
      buttonName: 'timeline-month-end',
      description: 'You want to accomplish this by the end of the month',
    },
    {
      title: 'End of the quarter',
      buttonName: 'timeline-quarter-end',
      description:
        'You want to accomplish this by the end of the next 3 months',
    },
    {
      title: 'End of the year',
      buttonName: 'timeline-year-end',
      description: 'You want to accomplish this by the end of the year',
    },
  ]

  const STATUS = [
    {
      title: 'Not Started',
      buttonName: 'status-not-started',
      description: 'You have not started this task',
    },
    {
      title: 'On Track',
      buttonName: 'status-on-track',
      description: 'You are on track to complete this task on time',
    },
    {
      title: 'Behind',
      buttonName: 'status-behind',
      description: 'You are behind schedule on this task',
    },
  ]

  // TODO: SWAP ACTIONS
  const ACTIONS = [
    {
      title: 'Start Tracking Your Goals',
      buttonName: 'action-add-milestones',
      description: 'Add more milestones to your plan',
    },
    {
      title: 'Start Learning',
      buttonName: 'action-generate-innovation-plan',
      description: 'Setup automations to help you accomplish your goals',
    },
    {
      title: 'Join a Workshop',
      buttonName: 'action-start-workflow',
      description: 'Start a workflow to help you accomplish your goals',
    },
  ]

  const ACTION_BUTTON_NAMES = [
    null,
    { customize: 'action-customize-goals', help: 'action-help-goals' },
    { customize: 'action-customize-models', help: 'action-help-models' },
    { customize: 'action-customize-audience', help: 'action-help-audience' },
    { customize: 'action-customize-timeline', help: 'action-help-milestones' },
    { customize: 'action-customize-status', help: 'action-help-progress' },
    6,
    { customize: 'action-customize-insights', help: 'action-help-insights' },
    { customize: 'action-customize-plan', help: 'action-help-plan' },
  ]

  const [step, setStep] = useState(0)
  const [goal, setGoal] = useState(null)
  const [model, setModel] = useState(null)
  const [activity, setActivity] = useState(null)
  const [audience, setAudience] = useState(null)
  const [completed, setCompleted] = useState(false)
  const [list, setList] = useState(null)
  const [message, setMessage] = useState('What is your top goal?')
  const [popupMessage, setPopupMessage] = useState('How can we help?')
  const [upgradeMessage, setUpgradeMessage] = useState('Upgrade ✨')
  const [loadingMessage, setLoadingMessage] = useState('')
  const [openPopup, setOpenPopup] = useState(false)
  const [video, setVideo] = useState(false)
  const [useAI, setUseAI] = useState(false)
  const [showUpgrade, setShowUpgrade] = useState(false)
  const [aiResponse, setAiResponse] = useState(null)
  const [activityIds, setActivityIds] = useState(null)
  const [activityInsights, setActivityInsights] = useState(null)
  const [loading, setLoading] = useState(false)
  const [insightsLoading, setInsightsLoading] = useState(false)
  const [firstClicks, setFirstClicks] = useState({})
  const [apiError, setAPIError] = useState(false)
  const [loginLoading, setLoginLoading] = useState(false)
  const [loginError, setLoginError] = useState(null)
  const [characters, setCharacters] = useState(50 - 0)
  const [customText, setCustomText] = useState('')
  const [milestoneText, setMilestoneText] = useState('')
  const [milestoneDate, setMilestoneDate] = useState('')
  const [milestone, setMilestone] = useState(null)
  const [progressText, setProgressText] = useState('')
  const [progress, setProgress] = useState(null)
  const [context, setContext] = useState(null)
  const [savedContext, setSavedContext] = useState(null)
  const [recommendation, setRecommendation] = useState(false)

  // const logActivityClick = async () => {
  //   // console.log('Clicked, starting test to db')
  //   let found = activityIds.find(
  //     (activityId) => activityId.activity === activity.value,
  //   )
  //   //console.log('found id', found)

  //   if (!found) return

  //   try {
  //     await fetch(
  //       'https://us-central1-tbdhow.cloudfunctions.net/logActivityClick',
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ activityId: found.id }),
  //       },
  //     )
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const renderStep = (step) => {
    switch (step) {
      case 0:
        setMessage(null)
        setList(null)
        break

      case 1:
        setMessage('Awesome! What are you working on?')
        // set state for some input action
        break

      case 2:
        setMessage('Okay no problem! What have you achieved so far?')
        break

      case 3:
        setMessage('What is your top goal for the next 3 months?')
        break

      case 3.1:
        setMessage('What milestones have you hit so far?')
        break

      case 4:
        setMessage('Talk to us about the team working on this with you')
        break

      case 5:
        setMessage('What is the biggest block to your progress right now?')
        break

      default:
        break
    }
  }

  // segment, goal, progress, team, blockers, recommendation

  // async method that calls fetch sending firstClicks and props.sessionId
  const saveFirstClicks = async () => {
    if (props.sessionId === null) return
    console.log('saving')
    try {
      await fetch(
        ' https://us-central1-tbdhow.cloudfunctions.net/updateSession',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            sessionId: props.sessionId,
            data: { firstClicks },
          }),
        },
      )
      console.log('saved')
    } catch (error) {
      console.log(error)
    }
  }

  const saveSessionComplete = async () => {
    console.log('saving')
    try {
      await fetch(
        ' https://us-central1-tbdhow.cloudfunctions.net/updateSession',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            sessionId: props.sessionId,
            data: { completed: true },
          }),
        },
      )
      console.log('session complete')
    } catch (error) {
      console.log(error)
    }
  }

  const saveSessionContext = async () => {
    console.log('saving')
    try {
      await fetch(
        ' https://us-central1-tbdhow.cloudfunctions.net/updateSession',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            sessionId: props.sessionId,
            data: { context, recommendation },
          }),
        },
      )
      console.log('session complete')
    } catch (error) {
      console.log(error)
    }
  }

  const logFirstActionClick = (name) => {
    console.log('in lfac', step, firstClicks, name)
    switch (step) {
      case 1:
        if (firstClicks.segment) return
        setFirstClicks({ ...firstClicks, segment: name })
        console.log('first click was ', name)
        break
      case 2:
        if (firstClicks.progress) return
        setFirstClicks({ ...firstClicks, progress: name })
        console.log('first click was ', name)
        break
      // case 3:
      //   if (firstClicks.goal) return
      //   setFirstClicks({ ...firstClicks, goal: name })
      //   console.log('first click was ', name)
      //   break
      case 4:
        if (firstClicks.team) return
        setFirstClicks({ ...firstClicks, team: name })
        console.log('first click was ', name)
        break
      // case 5:
      //   if (firstClicks.) return
      //   setFirstClicks({ ...firstClicks, milestone: name })
      //   console.log('first click was ', name)
      //   break
      case 6:
        if (firstClicks.recommendation) return
        setFirstClicks({ ...firstClicks, recommendation: name })
        console.log('first click was ', name)

      // case 6:
      //   if (firstClicks.activity) return
      //   setFirstClicks({ ...firstClicks, activity: name })
      //   console.log('first click was ', name)
      default:
        break
    }
  }

  // const renderActions = () => {
  //   if (step === 0) {
  //     return
  //   }
  //   if (step === 6) {
  //     return ACTIONS.map((value, index) => {
  //       return (
  //         <ActionOption
  //           key={index}
  //           value={index}
  //           name={value.buttonName}
  //           onClick={(event) => {
  //             let btnName = event.currentTarget.getAttribute('name')
  //             console.log(btnName, 'pressed')
  //             if (btnName) logButtonClick(btnName)
  //             if (btnName) logFirstActionClick(btnName)

  //             switch (index) {
  //               case 0:
  //                 alert("We're working on this feature! 🚧")
  //                 break
  //               case 1:
  //                 setStep(7)
  //                 break
  //               case 2:
  //                 setStep(8)
  //                 break
  //               default:
  //                 break
  //             }
  //           }}
  //         >
  //           {value.title}
  //         </ActionOption>
  //       )
  //     })
  //   } else {
  //     return [1, 'Other', 'Help'].map((value, index) => {
  //       return (
  //         <ActionOption
  //           hide={value === 1}
  //           name={
  //             index === 1
  //               ? ACTION_BUTTON_NAMES[step].customize
  //               : ACTION_BUTTON_NAMES[step].help
  //           }
  //           onClick={(event) => {
  //             let btnName = event.currentTarget.getAttribute('name')
  //             console.log(btnName, 'pressed')
  //             if (btnName) logButtonClick(btnName)
  //             if (btnName) logFirstActionClick(btnName)

  //             let topic =
  //               step === 1
  //                 ? 'top goal'
  //                 : step === 2
  //                 ? 'business model'
  //                 : step === 3
  //                 ? 'audience'
  //                 : step === 4
  //                 ? 'milestone'
  //                 : 'progress'

  //             if (index === 1) {
  //               setVideo(false)
  //               setUseAI(true)
  //               setPopupMessage(`Customize your ${topic}`)
  //               setUpgradeMessage('Submit')
  //               // let custom = prompt(`Tell us a bit more about your ${topic}!`)
  //             } else if (index === 2) {
  //               setVideo(true)
  //               setUseAI(false)

  //               setPopupMessage(`Learn how to define your ${topic} 📺`)
  //               setUpgradeMessage('Watch more videos ✨')
  //             }
  //             setOpenPopup(true)
  //           }}
  //         >
  //           {value}
  //         </ActionOption>
  //       )
  //     })
  //   }
  // }

  // toggle loading string between '.', '..', '...' every 500ms

  const toggleLoading = () => {
    const loadingStrings = ['.', '..', '...']
    let index = 0
    setInterval(() => {
      setLoadingMessage(`${loadingStrings[index]}`)
      index++
      if (index === loadingStrings.length) index = 0
    }, 500)
  }

  useEffect(() => {
    if (!context && !savedContext) {
      let savedContext = JSON.parse(localStorage.getItem('context'))
      setSavedContext(savedContext)
      // console.log('saved context loaded', savedContext)
      if (savedContext && savedContext.recommendation) {
        getSavedRecommendation(savedContext.recommendation)
        // if (savedContext.recommendation) await getRecommendation()
      }
    }
  })

  useEffect(() => {
    if (context && recommendation) {
      console.log('saving context and recommendation', context, recommendation)
      localStorage.setItem(
        'context',
        JSON.stringify({ ...context, recommendation }),
      )
      saveSessionContext()
    }
  }, [recommendation])

  useEffect(() => {
    renderStep(step)
  }, [step])

  useEffect(() => {
    if (!context) return

    const {
      segment,
      goal,
      goalProgress,
      progress,
      team,
      blockers,
      recommendation,
    } = context
    console.log('updated context', context)
    if (segment && goal && progress && team && blockers && recommendation) {
      console.log('adding recommendation to context', context)
      localStorage.setItem(
        'context',
        JSON.stringify({
          segment,
          goal,
          progress,
          team,
          blockers,
          recommendation,
        }),
      )
      saveSessionContext()
    } else if (segment && !progress) {
      setStep(2)
    } else if (segment && progress && !goal) {
      setStep(3)
    } else if (segment && progress && goal && !team) {
      //   setStep(4)
      // // } else if (
      //   segment &&
      //   segment == 'founder-ish' &&
      //   progress &&
      //   goal &&
      //   goalProgress &&
      //   !team
      // ) {
      setStep(4)
    } else if (segment && goal && progress && team && !blockers) {
      setStep(5)
    } else if (
      segment &&
      goal &&
      progress &&
      team &&
      blockers &&
      !recommendation
    ) {
      console.log('finished adding context!', context)
      localStorage.setItem(
        'context',
        JSON.stringify({ segment, goal, progress, team, blockers }),
      )
    }
    // else if (
    //   segment &&
    //   goal &&
    //   progress &&
    //   team &&
    //   blockers &&
    //   recommendation
    // ) {
    //   console.log('adding recommendation to context', context)
    //   localStorage.setItem(
    //     'context',
    //     JSON.stringify({
    //       segment,
    //       goal,
    //       progress,
    //       team,
    //       blockers,
    //       recommendation,
    //     }),
    //   )
    // }
  }, [context])

  useEffect(() => {
    console.log('saving first clicks', firstClicks)
    saveFirstClicks()
  }, [firstClicks, props.sessionId])

  const logButtonClick = async (name) => {
    // console.log('Clicked, starting test to db')

    let testName = name.includes('goal')
      ? 'test-goal'
      : name.includes('bm')
      ? 'test-bm'
      : name.includes('aud')
      ? 'test-aud'
      : name.includes('action')
      ? 'test-action'
      : 'test'

    const data = {
      buttonName: name,
    }

    try {
      const response = await fetch(
        'https://us-central1-tbdhow.cloudfunctions.net/incrementCounter',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      )
    } catch (error) {
      console.log(error)
    }
  }

  const askAI = async (data) => {
    const response = await fetch('/quiz/insights', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ context: data }),
    })
    const result = await response.json()
    try {
      let resultObject = JSON.parse(result)
      console.log(resultObject)
      // setAiResponse(result)
      // setLoading(false)
      // setCompleted(true)
      if (!resultObject) return null
      else return resultObject
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const analyzeProgress = () => {
    console.log('analyzing progress', context.progress)
    // if (!context.progress || context.progress.length < 2){
    //   console.log("progress", "needs to understand")
    //   return 'understand'
    // }
    if (context.segment === 'founder-ish') {
      if (context.progress.length === 6) {
        // you've done everything to understand your business model including developing a product and setting up a data-driven workflow
        console.log("founder doesn't need the LAP", 'adapt')
        return 'adapt'
      } else if (
        context.progress.length === 5 &&
        !context.progress.includes('Build a data-driven workflow')
      ) {
        // you've done everything but create a data-driven workflow
        console.log('founder needs a workflow', 'mobilize')
        return 'mobilize'
      } else if (
        context.progress.length === 5 &&
        !context.progress.includes('Developed a product or service')
      ) {
        console.log('founder needs to build a product', 'build')
        return 'build'
      } else {
        // you haven't set a clear business model
        console.log("founder doesn't have a clear business model", 'understand')
        return 'understand'
      }
    } else if (context.segment === 'corporate-preneur') {
      if (!context.progress.includes('Analyzed current org needs')) {
        // you haven't analyzed current org needs
        console.log("corporatepreneur hasn't understood problem")
        return 'understand'
      } else if (
        !context.progress.includes('Mobilized key stakeholders') ||
        !context.progress.includes('Defined SMART goals or KPIs') ||
        !context.progress.includes('Developed a strategic plan') ||
        !context.progress.includes('Built a data-driven workflow')
      ) {
        // you haven't mobilized key stakeholders
        console.log("corporatepreneur hasn't mobilized stakeholders")
        return 'mobilize'
      } else {
        console.log('corporatepreneur is in the build phase or needs to adapt')
        return 'build'
        // you've done everything except create a culture of innovation
      }
    } else {
      // you're a solutioneer
      if (
        !context.progress.includes('Identified a clear problem or goal') ||
        !context.progress.includes('Determined key stakeholders involved')
      ) {
        // you haven't identified a clear problem or goal or the stakeholders involved
        console.log("solutioneer hasn't understood problem")
        return 'understand'
      } else if (
        !context.progress.includes('Created a strategic plan') ||
        !context.progress.includes('Identified metrics to test progress') ||
        !context.progress.includes('Set up a data-driven workflow')
      ) {
        console.log("solutioneer hasn't mobilized stakeholders")
        // you haven't created a strategic plan
        return 'mobilize'
      } else if (!context.progress.includes('Developed a solution')) {
        console.log("solutioneer hasn't built solution")
        return 'build'
      } else {
        console.log('solutioneer is in the build phase or needs to adapt')
        return 'adapt'
      }
    }
  }

  const analyzeGoals = async () => {
    if (context.goal === 'idk' || context.blockers === 'idk') {
      // you're unsure of your goal or blockers
      return 'understand'
    } else {
      // ask AI for recommendation
      let data = `my goal is to ${context.goal} and my current block is ${context.blockers}`
      let result = await askAI(data)
      if (result) {
        console.log('ai said you need to: ', result)
        return result.step.toLowerCase()
      } else {
        console.log("ai didn't help lol")
        return 'understand'
      }
    }
  }

  const analyzeTeam = () => {
    if (
      (context.team.length > 1 && !context.team.includes("It's just me")) ||
      (context.team.length > 2 && context.team.includes("It's just me"))
    ) {
      // you're working with others
      return 'mobilize'
    } else {
      // analyze other data from context to determine if you have a plan or not
      // based on your segment determine if user has a strategic plan or workflow or clear goals if not return mobilize otherwise return understand or build or adapt
      return analyzeProgress()
    }
  }

  // const analyzeBlockers = () => {
  //   if (context.blockers !== 'idk') {
  //     // ask AI for recommendation
  //     let data = 'my current block is ' + context.blockers
  //     let result = askAI(data)
  //     if (result) {
  //       console.log('ai said your blocker needs: ', result)
  //       return result
  //     } else {
  //       console.log("ai didn't help lol")
  //       return 'understand'
  //     }
  //   } else {
  //     return 'understand'
  //   }
  // }

  const gatherInsights = async () => {
    let progressInsight = analyzeProgress()
    let goalInsight = await analyzeGoals()
    let teamInsight = analyzeTeam()
    // let blockerInsight = analyzeBlockers()

    let frequencies = {}
    frequencies[progressInsight] = (frequencies[progressInsight] || 0) + 1
    frequencies[goalInsight] = (frequencies[goalInsight] || 0) + 1
    frequencies[teamInsight] = (frequencies[teamInsight] || 0) + 1
    // frequencies[blockerInsight] = (frequencies[blockerInsight] || 0) + 1

    let max = 0
    let maxInsight = null
    for (let insight in frequencies) {
      if (frequencies[insight] > max) {
        max = frequencies[insight]
        maxInsight = insight
      }
    }

    console.log('insights', frequencies, maxInsight)
    // setContext({ ...context, recommendation: maxInsight })
    return maxInsight
  }

  const getRecommendation = async () => {
    console.log('getting recommendation')
    setMessage(
      '*Staring into our crystal ball to predict your best next step* 🔮',
    )
    toggleLoading()
    setLoading(true)

    let rec = await gatherInsights()

    setTimeout(() => {
      setLoading(false)
      switch (rec) {
        case 'understand':
          setRecommendation(INNOVATION_OUTCOMES.understand)
          break
        case 'mobilize':
          setRecommendation(INNOVATION_OUTCOMES.mobilize)
          break
        case 'build':
          setRecommendation(INNOVATION_OUTCOMES.build)
          break
        default:
          setRecommendation(INNOVATION_OUTCOMES.adapt)
      }

      // if (!context.recommendation)
      //   setContext({ ...context, recommendation: rec })
    }, 3000)
  }

  const getSavedRecommendation = (savedRecommendation) => {
    setMessage(
      '*Staring into our crystal ball to predict your best next step* 🔮',
    )
    toggleLoading()
    setLoading(true)
    setStep(6)

    let rec = savedRecommendation.step

    setTimeout(() => {
      setLoading(false)
      switch (rec) {
        case 'understand':
          setRecommendation(INNOVATION_OUTCOMES.understand)
          break
        case 'mobilize':
          setRecommendation(INNOVATION_OUTCOMES.mobilize)
          break
        case 'build':
          setRecommendation(INNOVATION_OUTCOMES.build)
          break
        default:
          setRecommendation(INNOVATION_OUTCOMES.adapt)
      }
    }, 3000)
  }

  const renderUserInput = () => {
    switch (step) {
      //Opener
      case 0:
        return (
          <Opener
            callBack={() => {
              setStep(1)
            }}
          />
        )

      // Segment
      case 1:
        return (
          <OptionsStep
            type={'large'}
            list={SEGMENTS}
            logButtonClick={logButtonClick}
            logFirstActionClick={logFirstActionClick}
            callBack={(data) => {
              switch (data.value) {
                case 'Building a Business':
                  setContext({ ...context, segment: 'founder-ish' })
                  break
                case 'Innovating an Org':
                  setContext({ ...context, segment: 'corporate-preneur' })
                  break
                case 'Solving a Problem':
                  setContext({ ...context, segment: 'solutioneer' })
                  break
                default:
                  break
              }
              console.log(context)
              setStep(2)
            }}
          />
        )
      // case 1:
      //   return (
      //     <InputStep
      //       message={'Tell us a little bit about your project or business.'}
      //       callBack={(data) => {
      //         setContext({ ...context, org: data })
      //         setStep(2)
      //       }}
      //     />
      //   )

      // Goals
      case 2:
        // based on the segment show the different goals
        let SEGMENT_MILESTONES = null

        if (context.segment === 'founder-ish')
          SEGMENT_MILESTONES = SEGMENTS[0].milestones
        else if (context.segment === 'corporate-preneur')
          SEGMENT_MILESTONES = SEGMENTS[1].milestones
        else if (context.segment === 'solutioneer')
          SEGMENT_MILESTONES = SEGMENTS[2].milestones

        return (
          <OptionsStep
            multiSelect
            list={SEGMENT_MILESTONES}
            logButtonClick={logButtonClick}
            logFirstActionClick={logFirstActionClick}
            callBack={(data) => {
              setContext({ ...context, progress: data.value })
            }}
          />
        )

      // GOALS
      case 3:
        // if (context.segment === 'founder-ish') {
        //   return (
        //     <OptionsStep
        //       list={STARTUP_GOALS}
        //       type={'large'}
        //       logButtonClick={logButtonClick}
        //       logFirstActionClick={logFirstActionClick}
        //       callBack={(data) => {
        //         setContext({ ...context, goal: data.value })
        //       }}
        //     />
        //   )
        // }
        return (
          <InputStep
            message={
              "Tell us a little bit about what you're trying to achieve."
            }
            callBack={(data) => {
              setContext({ ...context, goal: data })
            }}
          />
        )

      // Show milestones based on goal
      case 3.1:
        if (context.segment === 'founder-ish') {
          let goal = context.goal
          const LIST =
            goal === 'Generate Revenue'
              ? MILESTONES.Revenue
              : goal === 'Grow Audience'
              ? MILESTONES.Audience
              : MILESTONES.Operations

          return (
            <OptionsStep
              list={LIST}
              multiSelect={true}
              logButtonClick={logButtonClick}
              logFirstActionClick={logFirstActionClick}
              callBack={(data) => {
                setContext({ ...context, goalProgress: data })
              }}
            />
          )
        } else {
          setStep(4)
        }

      // Team and Roles
      case 4:
        return (
          <OptionsStep
            list={MILESTONES.Team}
            multiSelect={true}
            logButtonClick={logButtonClick}
            logFirstActionClick={logFirstActionClick}
            callBack={(data) => {
              setContext({ ...context, team: data })
            }}
          />
        )

      case 5:
        return (
          <InputStep
            message={
              "If you had a magic wand what's something you wish could change?"
            }
            callBack={(data) => {
              setContext({ ...context, blockers: data })
              getRecommendation()
            }}
          />
        )

      default:
        break
    }
  }

  return (
    <>
      {!apiError ? (
        <Wrapper>
          <UpgradeWrapper>
            {[1, 2, 3].map((value, index) => {
              if (value === 1) {
                return (
                  <LogoOption
                    key={index}
                    img={'floreo.png'}
                    onClick={() => {
                      window.location.href = '/'
                    }}
                  />
                )
              }
              return (
                <UpgradeOption
                  hide={value !== 1}
                  key={value}
                  value={'action-upgrade-btn-top'}
                  name={'action-upgrade-btn-top'}
                  onClick={(event) => {
                    // alert("We're working on this feature! 🚧")
                    let btnName = event.currentTarget.getAttribute('name')
                    console.log(btnName, 'pressed')
                    if (btnName) logButtonClick('action-upgrade-btn-top')
                    if (btnName) logFirstActionClick('action-upgrade-btn-top')
                    setShowUpgrade(true)
                    setUpgradeMessage('Join Beta ✨')
                    setPopupMessage(
                      'Personalize your business strategy using AI 🤖',
                    )
                    setOpenPopup(true)
                  }}
                >
                  {'Join Beta ✨'}
                </UpgradeOption>
              )
            })}
          </UpgradeWrapper>
          {!recommendation ? (
            <>
              <h1>{message}</h1>

              {!loading ? (
                renderUserInput()
              ) : (
                <Loading indicator={loadingMessage} />
              )}
            </>
          ) : (
            <Recommendation
              logFirstActionClick={logFirstActionClick}
              logButtonClick={logButtonClick}
              result={recommendation}
              saveSessionComplete={saveSessionComplete}
            />
          )}
        </Wrapper>
      ) : (
        <Error />
      )}
    </>
  )
}

const Loading = (props) => {
  const { loadingMessage, indicator } = props

  return (
    <>
      <p>{loadingMessage}</p> <h1>{indicator}</h1>
    </>
  )
}

const QuizOption = (props) => {
  // create an Option with a tooltip button that opens a popup
  const {
    children,
    action,
    onClick,
    hide,
    className,
    selected,
    large,
    value,
  } = props
  const { isMobile, windowDimension } = useSize()
  return isMobile ? (
    <Option
      action={action}
      hide={hide}
      large={large}
      selected={selected}
      value={value}
      onClick={(e) => {
        console.log(e.target.className)
        if (e.target.className.includes('popup-overlay')) return
        onClick(e)
      }}
    >
      {value.title}
      <Popup
        trigger={
          <div className="tooltip">
            <HelpCircle size={20} color="rgb(99, 80, 232)" />
          </div>
        }
        nested
        modal
      >
        <div>
          <p>{value.description}</p>
        </div>
      </Popup>
    </Option>
  ) : (
    <Popup
      trigger={
        <Option
          hide={hide}
          action={action}
          large={large}
          value={value}
          name={value.buttonName}
          selected={selected}
          onClick={onClick}
        >
          {value.title}
        </Option>
      }
      position={'top center'}
      on={['hover', 'focus']}
      arrow={'top left' !== 'center center'}
    >
      <div>
        <p>{value.description}</p>
      </div>
    </Popup>
  )
  // return (
  //   <Option
  //     className="tooltip-option"
  //     onClick={(e) => {
  //       console.log(e.target.className)
  //       if (e.target.className.includes('popup-overlay')) return
  //       alert('Tooltip! 🚧')
  //     }}
  //   >
  //     Testing
  //     <Popup
  //       trigger={
  //         <div className="tooltip">
  //           <HelpCircle size={20} color="rgb(99, 80, 232)" />
  //         </div>
  //       }
  //       nested
  //     />
  //   </Option>
  // )
}

const Opener = (props) => {
  const { isMobile, windowDimension } = useSize()
  // console.log(isMobile, windowDimension)
  return (
    <>
      <div className="opener">
        <p>
          Ready to move your ideas to greater profit or impact? <br /> <br />
          This quick lab will help you identify where to focus your strategy.
        </p>
      </div>
      <OptionsWrapper>
        <Option
          id="opener-btn"
          onClick={() => {
            props.callBack()
          }}
        >
          Let's Get Started!
        </Option>
      </OptionsWrapper>
    </>
  )
}

const INNOVATION_OUTCOMES = {
  understand: {
    step: 'understand',
    action: 'Understanding your goals',
    description:
      "This step in the innovation process is all about understanding the problem you're solving and your goals. We recommend you start by defining your value in relationship to your key stakeholders.",
  },
  mobilize: {
    step: 'mobilize',
    action: 'Mobilizing your team',
    description:
      'This step in the innovation process is all about mobilizing your team to achieve your goals. We recommend you start by creating a strategic plan that clearly defines your goals, tactics and team roles and responsibilities.',
  },
  build: {
    step: 'build',
    action: 'Building your workflows',
    description:
      'This step in the innovation process is all about building the resources to help you achieve your goals. We recommend you start by creating a workflow for each of your goals that clearly defines the deliverables and milestones you need to achieve them.',
  },
  adapt: {
    step: 'adapt',
    action: 'Reflecting and adapting your processes',
    description:
      "This step in the innovation process is all about adapting your strategy to better align to your goals. We recommend you start by reviewing your progress and identifying what's working and what's not and updating your strategic plan to reflect your new goals and tactics.",
  },
}

const Recommendation = (props) => {
  const [loginLoading, setLoginLoading] = useState(false)
  const [loginError, setLoginError] = useState(null)
  const { result } = props

  useEffect(() => {
    props.saveSessionComplete()
  }, [])

  return (
    <div className="recommendation">
      <h1>You should focus on: {result.action}</h1>
      <p>
        {result.description}
        <br />
        <br />
        <b>Here are some resources to help you get started:</b>
        <br />
        <br />
      </p>
      <OptionsWrapper>
        <QuizOption
          action
          value={{
            title: 'Download our free guide 📖',
            description:
              'Practical tips and examples on everything from setting smarter goals to driving business growth!',
            buttonName: 'action-guide-btn',
          }}
          onClick={(event) => {
            let btnName = event.currentTarget.getAttribute('name')
            console.log(btnName, 'pressed')
            if (btnName) props.logButtonClick(btnName)
            if (btnName) props.logFirstActionClick(btnName)

            let email = prompt("What's your email?")
            if (email) {
              alert(
                `Thanks for your interest! You'll get a download link at ${email} soon!`,
              )
              sendLeadGeneratorLink(email)
            }
          }}
        />
        {result.step === 'understand' ? (
          <QuizOption
            action
            value={{
              title: 'Get customer ready 🚀',
              description:
                'Learn how to launch your business in 2 months in our digital accelerator program!',
              buttonName: 'action-lap-btn',
            }}
            onClick={(event) => {
              let btnName = event.currentTarget.getAttribute('name')
              console.log(btnName, 'pressed')
              if (btnName) props.logButtonClick(btnName)
              if (btnName) props.logFirstActionClick(btnName)

              window.open('https://learn.floreolabs.org', '_blank')
            }}
          />
        ) : (
          <QuizOption
            action
            value={{
              title: 'Join our next webinar 🌊',
              description:
                'Learn how to develop a data-driven workflow that will achieve your goals!',
              buttonName: 'action-webinar-btn',
            }}
            onClick={(event) => {
              let btnName = event.currentTarget.getAttribute('name')
              console.log(btnName, 'pressed')
              if (btnName) props.logButtonClick(btnName)
              if (btnName) props.logFirstActionClick(btnName)

              window.open(
                'https://www.floreolabs.org/event-details/start-a-data-driven-workflow-that-gets-you-results',
                '_blank',
              )
            }}
          />
        )}
        <QuizOption
          action
          value={{
            title: 'Build a custom plan ⚡️',
            description:
              'Connect with our team to create a custom strategic plan for your work that will save you time, money and energy!',
            buttonName: 'action-workshop-btn',
          }}
          onClick={(event) => {
            let btnName = event.currentTarget.getAttribute('name')
            console.log(btnName, 'pressed')
            if (btnName) props.logButtonClick(btnName)
            if (btnName) props.logFirstActionClick(btnName)

            window.open('https://floreolabs.org/workshops', '_blank')
          }}
        />
      </OptionsWrapper>
      <div
        className="save-button"
        onClick={() => {
          if (
            window.confirm(
              'Hey friend! This will delete your previous results so you can retake the quiz!',
            )
          ) {
            localStorage.removeItem('context')
            window.location.reload()
          }
        }}
      >
        <span>Start Over!</span>
      </div>
    </div>
  )
}

const InputStep = (props) => {
  const { message, characters, callBack, countCallBack } = props
  const [text, setText] = useState('')
  const [count, setCount] = useState(50 - text.length)

  return (
    <div>
      <p>
        {message} Type <span class="emphasis">idk</span> if you're not sure!
      </p>
      <InputForm>
        <label for="counter-input" class="label">
          Character count:{' '}
          <span id="counter-display" class="tag is-success">
            {count}
          </span>
        </label>
        <textarea
          class="textarea"
          maxlength="50"
          name="textarea"
          id="counter-input"
          cols="30"
          onChange={(e) => {
            setText(e.target.value)
          }}
          onKeyUp={(e) => {
            setCount(50 - e.target.value.length)
          }}
        ></textarea>
      </InputForm>
      <OptionsWrapper>
        <Option
          onClick={(e) => {
            if (text === '') return
            callBack(text)
          }}
        >
          Submit
        </Option>
      </OptionsWrapper>
    </div>
  )
}

const OptionsStep = (props) => {
  const { step, list, logButtonClick, logFirstActionClick, callBack } = props

  const [selected, setSelected] = useState(null)

  return (
    <>
      <OptionsWrapper id="user-options">
        {list !== null &&
          list.map((value, index) => {
            return (
              <QuizOption
                key={index}
                value={value}
                large={props.type === 'large'}
                color={props.action}
                selected={selected && selected.includes(value.title)}
                onClick={(event) => {
                  if (props.multiSelect) {
                    if (selected && selected.includes(value.title)) {
                      let newSelected = selected.filter(
                        (item) => item !== value.title,
                      )
                      setSelected(newSelected)
                      // callBack({ index, value: newSelected })
                    } else {
                      let newSelected = selected
                        ? [...selected, value.title]
                        : [value.title]
                      setSelected(newSelected)
                      // callBack({ index, value: newSelected })
                    }
                  } else {
                    let btnName = event.currentTarget.getAttribute('name')
                    console.log(btnName, 'pressed')
                    if (btnName) logButtonClick(btnName)
                    if (btnName) logFirstActionClick(btnName)
                    callBack({ index, value: value.title })
                  }
                }}
              />
            )
            // return props.type === 'large' ? (
            //   <Popup
            //     key={index}
            //     trigger={
            //       <GoalOption
            //         key={index}
            //         value={index}
            //         name={value.buttonName}
            //         selected={selected && selected.includes(value.title)}
            //         onClick={(event) => {
            //           if (props.multiSelect) {
            //             if (selected && selected.includes(value.title)) {
            //               let newSelected = selected.filter(
            //                 (item) => item !== value.title,
            //               )
            //               setSelected(newSelected)
            //               // callBack({ index, value: newSelected })
            //             } else {
            //               let newSelected = selected
            //                 ? [...selected, value.title]
            //                 : [value.title]
            //               setSelected(newSelected)
            //               // callBack({ index, value: newSelected })
            //             }
            //           } else {
            //             let btnName = event.currentTarget.getAttribute('name')
            //             console.log(btnName, 'pressed')
            //             if (btnName) logButtonClick(btnName)
            //             if (btnName) logFirstActionClick(btnName)
            //             callBack({ index, value: value.title })
            //           }
            //         }}
            //       >
            //         {value.title}
            //       </GoalOption>
            //     }
            //     position={'top center'}
            //     on={['hover', 'focus']}
            //     arrow={'top left' !== 'center center'}
            //   >
            //     <div>
            //       <p>{value.description}</p>
            //     </div>
            //   </Popup>
            // ) : (
            //   <Popup
            //     key={index}
            //     trigger={
            //       <Option
            //         key={index}
            //         value={index}
            //         name={value.buttonName}
            //         selected={selected && selected.includes(value.title)}
            //         onClick={(event) => {
            //           if (props.multiSelect) {
            //             if (selected && selected.includes(value.title)) {
            //               let newSelected = selected.filter(
            //                 (item) => item !== value.title,
            //               )
            //               setSelected(newSelected)
            //               // callBack({ index, value: newSelected })
            //             } else {
            //               let newSelected = selected
            //                 ? [...selected, value.title]
            //                 : [value.title]
            //               setSelected(newSelected)
            //               // callBack({ index, value: newSelected })
            //             }
            //           } else {
            //             let btnName = event.currentTarget.getAttribute('name')
            //             console.log(btnName, 'pressed')
            //             if (btnName) logButtonClick(btnName)
            //             if (btnName) logFirstActionClick(btnName)
            //             callBack({ index, value: value.title })
            //           }
            //         }}
            //       >
            //         {value.title || value}
            //       </Option>
            //     }
            //     position={'top center'}
            //     on={['hover']}
            //     arrow={'top left' !== 'center center'}
            //   >
            //     <div>
            //       <p>{value.description}</p>
            //     </div>
            //   </Popup>
            // )
          })}
      </OptionsWrapper>
      {props.multiSelect && (
        <OptionsWrapper>
          <ActionOption
            onClick={() => {
              if (!selected || selected.length === 0) {
                setSelected(null)
                callBack({ value: [] })
                return
              } else {
                callBack({ value: selected })
                setSelected(null)
              }
            }}
          >
            {selected && selected.length > 0 ? 'Submit' : 'None Yet'}
          </ActionOption>
          {/* <Option
            hide={!selected || selected.length === 0}
            onClick={() => {
              callBack({ value: selected })
              setSelected(null)
            }}
          >
            Submit
          </Option> */}
        </OptionsWrapper>
      )}
    </>
  )
}

// const TeamInputStep = () => {
//   const [team, setTeam] = useState([])
//   const [teamMember, setTeamMember] = useState({ name: '', role: '' })
//   const [editTeam, setEditTeam] = useState(false)
//   const renderTeam = () => {
//     return team.map((value, index) => {
//       <Option>
//         {value.name} - {value.role}
//       </Option>
//     })
//   }

//   const OPTIONS = [
//     {
//       title: 'Driver',
//       description: 'This person is responsible for executing this task',
//     },
//     {
//       title: 'Approver',
//       description: 'This person is responsible for approving this task',
//     },
//     {
//       title: 'Consultant',
//       description: 'You are responsible for consulting on this task',
//     },
//     {
//       title: 'Informed',
//       description:
//         'This person is responsible for being informed about this task',
//     },
//   ]

//   return (
//     <>
//       {team.length === 0 && renderTeam()}
//       <OptionsWrapper>
//         <Option
//           type="large"
//           onClick={() => {
//             setEditTeam(true)
//           }}
//         >
//           Add Team Member
//         </Option>

//         <Option type="large">It's Just Me</Option>
//       </OptionsWrapper>

//       <Option>Submit</Option>
//       {editTeam && (
//         <StyledPopup
//           open={editTeam}
//           closeOnDocumentClick={true}
//           onClose={() => {
//             console.log('closing')
//             setEditTeam(false)
//           }}
//           modal
//         >
//           <PopUpMessage>
//             <div id="message-container">
//               <h1>Add a team member</h1>
//               <span
//                 onClick={() => {
//                   setEditTeam(false)
//                 }}
//               >
//                 Back
//               </span>
//             </div>
//             <form>
//               <label for="name">Name</label>
//               <input
//                 type="text"
//                 name="name"
//                 id="name"
//                 onChange={(e) => {
//                   setTeamMember({ ...teamMember, name: e.target.value })
//                 }}
//               />
//               <label for="role">Role</label>
//               <select
//                 name="role"
//                 id="role"
//                 onChange={(e) => {
//                   setTeamMember({ ...teamMember, role: e.target.value })
//                 }}
//               >
//                 {OPTIONS.map((value, index) => {
//                   return <option value={value.title}>{value.title}</option>
//                 })}
//               </select>
//               <input
//                 type="submit"
//                 value="Submit"
//                 onClick={(e) => {
//                   e.preventDefault()
//                   setTeam([...team, teamMember])
//                   setTeamMember({ name: '', role: '' })
//                 }}
//               />
//             </form>
//           </PopUpMessage>
//         </StyledPopup>
//       )}
//     </>
//   )
// }

export default Quiz
