import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebaseConfig'
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { PageWrapper } from './styled'

export const VerifyPage = () => {
  const [user] = useAuthState(auth)
  const [loading, setLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const { search } = location

  const toggleLoading = () => {
    const loadingStrings = ['.', '..', '...']
    let index = 0
    setInterval(() => {
      setLoadingMessage(`${loadingStrings[index]}`)
      index++
      if (index === loadingStrings.length) index = 0
    }, 500)
  }

  useEffect(() => {
    let interval = toggleLoading()
    if (user) {
      navigate('/')
    } else {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = localStorage.getItem('emailForSignIn')
        if (!email) {
          email = prompt('Please provide your email for confirmation')
          window.localStorage.setItem('emailForSignIn', email)
        }
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            console.log(result.user)
            window.localStorage.removeItem('emailForSignIn')
            navigate('/')
          })
          .catch((error) => {
            console.log(error)
            navigate('/login')
          })
      } else {
        navigate('/')
      }
    }
    return () => {
      setLoading(false)
      clearInterval(interval)
    }
  }, [user, search, navigate])

  return (
    <PageWrapper>
      <div className="navbar">
        <div
          className="logo"
          onClick={() => {
            window.location.href = '/'
          }}
        >
          <img src="floreo.png" alt="logo" />
        </div>
      </div>
      {loading && <h1>Loading{loadingMessage}</h1>}
    </PageWrapper>
  )
}
