import { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { List, Calendar, Activity } from 'react-feather'
import DataTable from 'react-data-table-component'

function Goals(props) {
  const [windowDimension, setWindowDimension] = useState(null)

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobile = windowDimension <= 425

  const COLUMNS = [
    {
      name: 'Owner',
      selector: 'owner',
      compact: true,
      width: '10%',
    },
    {
      name: 'Due',
      selector: 'due',
      compact: true,
      width: '8%',
    },
    {
      name: 'Milestone',
      selector: 'name',
      minWidth: '30%',
      wrap: true,
    },
    {
      name: '%',
      selector: 'progress',
      compact: true,
      width: '8%',
    },
    {
      name: 'Status',
      selector: 'status',
      compact: true,
      width: '10%',
      wrap: true,
    },
    {
      name: 'Change',
      selector: 'change',
      compact: true,
      width: '10%',
    },
    {
      name: 'Morale',
      selector: 'morale',
      compact: true,
      width: '10%',
    },
  ]

  const GOALS = [
    {
      title: 'Raise 250k SAFE by Demo Day',
      milestones: [
        {
          name: 'Host 25 new investor meetings by end of Q3',
          owner: 'Alex',
          due: '09/31',
          progress: '70%',
          status: 'On Track',
          change: '↑20',
          morale: '🙂',
        },
        {
          name: 'Finalize 100% of the VC prep checklist by July',
          owner: 'Alex',
          due: '06/30',
          progress: '70%',
          status: 'On Track',
          change: '↑20',
          morale: '🙂',
        },
        {
          name: 'Add 200 warm investor contacts by end of Q2',
          owner: 'Alex',
          due: '06/30',
          progress: '60%',
          status: 'On Track',
          change: '→',
          morale: '🙂',
        },
      ],
    },
  ]

  return (
    <Styles.Wrapper>
      {/* <CSSReset /> */}

      {isMobile ? (
        <MobileGoals.Wrapper></MobileGoals.Wrapper>
      ) : (
        <DesktopGoals.Wrapper>
          <div className="section-header">
            <h1>{GOALS[0].title}</h1>
            <button
              onClick={() => {
                window.confirm('W')
              }}
            >
              +
            </button>
          </div>
          {/* {          <DesktopGoals.Table>
            <thead>
              <tr>
                <th>Owner</th>
                <th>Due</th>
                <th>Milestone</th>
                <th>%</th>
                <th>Status</th>
                <th>Change</th>
                <th>Morale</th>
              </tr>
            </thead>
            <tbody>
              {GOALS[0].milestones.map((milestone) => {
                return (
                  <tr>
                    <td>{milestone.owner}</td>
                    <td>{milestone.due}</td>
                    <td>{milestone.name}</td>
                    <td>{milestone.progress}</td>
                    <td>{milestone.status}</td>
                    <td>{milestone.change}</td>
                    <td>{milestone.morale}</td>
                  </tr>
                )
              })}
            </tbody>
          </DesktopGoals.Table>} */}
          <DataTable
            className="Table"
            columns={COLUMNS}
            data={GOALS[0].milestones}
            responsive={true}
            noDataComponent={<div>No data</div>}
            theme="dark"
          />
        </DesktopGoals.Wrapper>
      )}
    </Styles.Wrapper>
  )
}

const Styles = {
  Wrapper: styled.div`
    // width: 50%;
    flex-direction: column;
  `,
}

const DesktopGoals = {
  Wrapper: styled.div`
    padding: 1rem 3rem;

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        border-radius: 50%;
        border: none;
        text-align: center;
      }
    }

    .Table {
      width: 100%;
    }
  `,
}

const MobileGoals = {
  Wrapper: styled(DesktopGoals.Wrapper)`
    position: fixed;
    width: 100vw;
    bottom: 0;

    justify-content: center;
  `,
}

export default Goals
