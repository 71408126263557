import styled from 'styled-components/macro'

export const PageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: linear-gradient(
    180deg,
    #6350e8 0%,
    rgba(99, 80, 232, 0.72) 100%
  );
`
