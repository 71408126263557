import { PageWrapper } from './styled'
import BYOB from '../../byob'
import Quiz from '../../byob/quiz'
import Dashboard from '../../dashboard'
import { useState } from 'react'
import PricingContainer from '../../pricing'

const Home = (props) => {
  const [onboarding, setOnboarding] = useState(true)
  const [context, setContext] = useState({})

  const onOnboardingDone = (values) => {
    setOnboarding(false)
    setContext(values)
  }

  return (
    <PageWrapper>
      {onboarding && (
        <Quiz onDone={onOnboardingDone} sessionId={props.sessionId} />
      )}
      {!onboarding && <Dashboard />}
    </PageWrapper>
  )
}

export default Home
