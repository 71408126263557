import { PageWrapper, Wrapper, Option } from './styled'

export const FourOFour = () => {
  return (
    <PageWrapper>
      <Wrapper>
        <div className="navbar">
          <div
            className="logo"
            onClick={() => {
              window.location.href = '/'
            }}
          >
            <img src="floreo.png" alt="logo" />
          </div>
          {/* <div className="nav-links">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/contact">Contact Us</a></li>
                    <li><a href="/login">Login</a></li>
                </ul>
            </div> */}
        </div>
        <div className="error">
          <p className="error-msg">Hey friend! We couldn't find that page 🥺</p>
          <img
            className="error-img"
            src={
              'https://media.tenor.com/601zsjqXi-YAAAAd/were-sorry-tony-hayward.gif'
            }
          />
          <Option
            onClick={() => {
              // send to home page
              window.location.href = '/'
            }}
          >
            Let's go home!
          </Option>
        </div>
      </Wrapper>
    </PageWrapper>
  )
}
