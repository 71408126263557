import styled from 'styled-components'

export const PageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: linear-gradient(
    180deg,
    #6350e8 0%,
    rgba(99, 80, 232, 0.72) 100%
  );
  color: #fff;

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 3%;
    background-color: transparent;
    color: #444;
    position: relative;
  }

  .navbar .logo {
    width: 150px;
    cursor: pointer;
  }

  .navbar .logo img {
    width: 100%;
  }
`
